<template>
  <auth-container>
    <div class="password-reset">
      <div class="container-wrapper w-100">
        <svg class="logo" width="144" height="33" viewBox="0 0 144 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.5919 3.14453L7.94141 7.94252L13.7586 11.7974L19.5757 15.6545L19.5919 3.14453Z" fill="#26C0E2" />
          <path d="M31.2286 10.8584L19.5781 15.6541L25.3953 19.5113L31.2125 23.3684L31.2286 10.8584Z" fill="#2B7BEF" />
          <path d="M25.4114 7.00169L19.5943 3.14453L19.5781 15.6545L31.2286 10.8589L25.4114 7.00169Z" fill="#2D68EA" />
          <path d="M26.6136 0.256594L19.5938 3.14546L25.4109 7.00262L31.2281 10.8598L31.2373 3.32401C31.2419 0.957063 28.8158 -0.649896 26.6136 0.256594Z" fill="#2458EA" />
          <path d="M7.94317 7.94336L2.06138 10.363C-0.40399 11.377 -0.734091 14.71 1.48428 16.1819L2.10755 16.5962L7.92471 20.4534L7.94317 7.94336Z" fill="#8941EF" />
          <path d="M7.92578 20.4511L19.5763 15.6554L13.7591 11.7982L7.94194 7.94336L7.92578 20.4511Z" fill="#5925E8" />
          <path d="M31.2091 23.3701L31.1998 29.6835C31.1952 32.332 28.2174 33.9069 25.999 32.435L25.3758 32.0207L19.5586 28.1635L31.2091 23.3701Z" fill="#8941EF" />
          <path d="M19.5586 28.1653L19.5748 15.6553L25.3919 19.5124L31.2091 23.3696L19.5586 28.1653Z" fill="#5925E8" />
          <path d="M7.91016 32.9612L19.5606 28.1655L13.7435 24.3083L7.92631 20.4512L7.91016 32.9612Z" fill="#FFBF1C" />
          <path d="M52.2838 14.3346H48.8697C48.7404 13.4625 48.3665 12.7803 47.7478 12.2813C47.1292 11.7845 46.3582 11.535 45.4325 11.535C44.1952 11.535 43.2049 11.9837 42.457 12.8833C41.7114 13.7829 41.3374 15.058 41.3374 16.7061C41.3374 18.3978 41.7137 19.682 42.4639 20.561C43.2141 21.44 44.1952 21.8795 45.4071 21.8795C46.3074 21.8795 47.0669 21.646 47.6924 21.1791C48.3157 20.7121 48.7081 20.0574 48.8697 19.2104L52.2838 19.2287C52.1638 20.197 51.8152 21.1104 51.2381 21.9665C50.661 22.8227 49.8785 23.5163 48.8882 24.0473C47.8979 24.5784 46.7183 24.8439 45.3517 24.8439C43.9274 24.8439 42.6555 24.5235 41.5359 23.8848C40.4164 23.2461 39.5322 22.3213 38.8859 21.1081C38.2395 19.8972 37.9141 18.4298 37.9141 16.7084C37.9141 14.9824 38.2419 13.5128 38.8974 12.3019C39.553 11.0909 40.4418 10.1661 41.5636 9.52976C42.6855 8.89338 43.9505 8.5752 45.354 8.5752C46.5867 8.5752 47.697 8.80182 48.6873 9.25277C49.6777 9.70373 50.4879 10.3584 51.1204 11.2168C51.7506 12.073 52.1384 13.1122 52.2838 14.3346Z" fill="#151518" />
          <path d="M57.9702 8.79004V24.6239H54.6484V9.90484C54.6484 9.28907 55.1517 8.79004 55.7726 8.79004H57.9702Z" fill="#151518" />
          <path d="M60.6211 24.6269V13.8658C60.6211 13.25 61.1243 12.751 61.7453 12.751H63.9429V24.6269H60.6211Z" fill="#151518" />
          <path d="M72.005 24.8572C70.7793 24.8572 69.7243 24.5985 68.8425 24.0812C67.9607 23.5638 67.2867 22.8428 66.8157 21.9202C66.3448 20.9977 66.1094 19.9356 66.1094 18.7338C66.1094 17.5183 66.3471 16.4493 66.8227 15.529C67.2982 14.6088 67.9746 13.89 68.8541 13.3727C69.7336 12.8553 70.777 12.5967 71.9889 12.5967C73.0346 12.5967 73.951 12.7844 74.7382 13.1621C75.5253 13.5375 76.1463 14.0663 76.601 14.7462C77.0558 15.426 77.3074 16.2249 77.3536 17.1429H74.2188C74.1311 16.55 73.8979 16.0716 73.5216 15.7076C73.1454 15.3436 72.6514 15.1628 72.0443 15.1628C71.2756 15.1628 70.6569 15.4695 70.1883 16.083C69.7197 16.6965 69.4866 17.5641 69.4866 18.688C69.4866 19.8211 69.7197 20.7001 70.1837 21.3205C70.6477 21.9408 71.2686 22.2522 72.0443 22.2522C72.6168 22.2522 73.0969 22.0805 73.487 21.7371C73.8771 21.3937 74.1218 20.9062 74.2188 20.2721H77.3536C77.3005 21.1786 77.0535 21.9775 76.608 22.6688C76.1625 23.3601 75.5507 23.898 74.7728 24.2803C73.9949 24.6626 73.0715 24.8572 72.005 24.8572Z" fill="#151518" />
          <path d="M79.4883 24.6268V9.90777C79.4883 9.292 79.9915 8.79297 80.6125 8.79297H82.8101V17.2604H82.9901L86.8267 12.7531H90.6401L86.1965 17.8945L90.8664 24.6291H86.9744L83.708 19.8425L82.8124 20.8543V24.6268H79.4883Z" fill="#151518" />
          <path d="M92.3203 24.6268V9.90777C92.3203 9.292 92.8235 8.79297 93.4445 8.79297H102.893V11.5536H95.6952V15.3261H102.191V18.0845H95.6952V24.6268H92.3203Z" fill="#151518" />
          <path d="M108.427 8.79004V24.6239H105.105V9.90484C105.105 9.28907 105.609 8.79004 106.23 8.79004H108.427Z" fill="#151518" />
          <path d="M114.489 24.8497C113.346 24.8497 112.398 24.5544 111.643 23.9615C110.888 23.3686 110.512 22.485 110.512 21.3084C110.512 20.4225 110.722 19.7266 111.144 19.2207C111.564 18.7148 112.118 18.3463 112.802 18.1105C113.485 17.877 114.221 17.7237 115.013 17.6504C116.052 17.5474 116.795 17.4398 117.243 17.3254C117.691 17.2109 117.912 16.9637 117.912 16.5837V16.5379C117.912 16.048 117.758 15.6703 117.448 15.4002C117.139 15.1324 116.701 14.9973 116.135 14.9973C115.537 14.9973 115.062 15.1255 114.708 15.3842C114.355 15.6429 114.122 15.9702 114.007 16.3662L110.934 16.119C111.163 15.0362 111.731 14.1778 112.638 13.5437C113.545 12.9096 114.715 12.5938 116.151 12.5938C117.04 12.5938 117.871 12.7334 118.647 13.0104C119.422 13.2896 120.045 13.7223 120.521 14.3129C120.996 14.9035 121.234 15.6703 121.234 16.6134V24.6231H118.083V22.9772H117.989C117.698 23.5289 117.261 23.9775 116.682 24.3255C116.103 24.6757 115.371 24.8497 114.489 24.8497ZM115.44 22.5766C116.167 22.5766 116.765 22.366 117.234 21.9471C117.702 21.5259 117.936 21.004 117.936 20.3813V19.12C117.806 19.207 117.603 19.2848 117.331 19.3512C117.058 19.4176 116.767 19.4771 116.458 19.5252C116.149 19.5733 115.872 19.6168 115.627 19.6534C115.045 19.7358 114.577 19.8983 114.219 20.141C113.864 20.3836 113.686 20.7361 113.686 21.2008C113.686 21.6541 113.85 21.9974 114.18 22.2286C114.51 22.4598 114.93 22.5766 115.44 22.5766Z" fill="#151518" />
          <path d="M123.812 24.6264V13.8652C123.812 13.2495 124.316 12.7504 124.937 12.7504H127.033V14.8221H127.157C127.377 14.085 127.741 13.5265 128.252 13.1488C128.764 12.7711 129.357 12.5811 130.034 12.5811C130.357 12.5811 130.655 12.62 130.929 12.6978C131.204 12.7756 131.451 12.8809 131.67 13.0137L130.673 15.7355C130.516 15.6576 130.348 15.5912 130.163 15.5386C129.978 15.4837 129.773 15.4585 129.542 15.4585C128.872 15.4585 128.305 15.6851 127.838 16.1383C127.372 16.5916 127.139 17.1822 127.132 17.9078V24.6264H123.812Z" fill="#151518" />
          <path d="M138.122 24.8572C136.896 24.8572 135.836 24.6077 134.947 24.1109C134.059 23.6142 133.375 22.9069 132.898 21.9935C132.42 21.0778 132.18 19.9951 132.18 18.743C132.18 17.5206 132.42 16.4493 132.898 15.5267C133.375 14.6042 134.049 13.8855 134.92 13.3704C135.79 12.8553 136.813 12.5967 137.988 12.5967C139.043 12.5967 139.996 12.8187 140.845 13.2605C141.695 13.7046 142.369 14.373 142.868 15.2658C143.366 16.1608 143.616 17.2802 143.616 18.6262V19.5304H135.46V19.5373C135.46 20.4232 135.705 21.1213 136.192 21.6318C136.679 22.1423 137.341 22.3964 138.172 22.3964C138.729 22.3964 139.209 22.2796 139.615 22.0484C140.021 21.8172 140.305 21.4739 140.474 21.0206L143.546 21.2221C143.313 22.3254 142.72 23.2067 141.769 23.866C140.82 24.5275 139.604 24.8572 138.122 24.8572ZM135.465 17.4885H140.495C140.49 16.7835 140.261 16.1997 139.809 15.7419C139.357 15.2841 138.77 15.0529 138.048 15.0529C137.309 15.0529 136.704 15.291 136.231 15.7648C135.758 16.2387 135.499 16.8132 135.465 17.4885Z" fill="#151518" />
          <path d="M62.4863 11.7939C63.5164 11.7939 64.3515 10.9658 64.3515 9.94433C64.3515 8.92282 63.5164 8.09473 62.4863 8.09473C61.4562 8.09473 60.6211 8.92282 60.6211 9.94433C60.6211 10.9658 61.4562 11.7939 62.4863 11.7939Z" fill="#26C0E2" />
        </svg>

        <b-form class="password-reset__confirm-email" @submit="(e) => e.preventDefault()" v-if="step === 'confirm_email'">
          <p class="subtitle">
            Enter the email associated with your ClickFlare account. We will send you the instructions on how to reset your password.
          </p>
          <b-form-group
            class="tracker-form-group w-100"
            label="Email"
            style="margin-top: 2.5rem"
            :invalid-feedback="$_getError('email')"
            :state="$_hasError('email')"
          >
            <b-form-input
              class="tracker-form-input"
              data-vv-as="Email"
              autocomplete="off"
              :state="$_hasError('email')"
              v-validate="'required|email'"
              type="email"
              v-model="email"
              name="email"
              key="email"
              placeholder="example@domain.com"
              @keyup.enter="requestPasswordReset"
            />
          </b-form-group>

          <b-btn
            class="tracker-primary-button--gradient w-100 mt-4 big-button"
            @click="requestPasswordReset"
            :disabled="loading"
          >
            <template v-if="loading">
              <i class="fa fa-spinner fa-spin mr-2"></i>
            </template>Send Code
          </b-btn>
        </b-form>

        <b-form v-if="step === 'redeem_code'">
          <p class="subtitle pin-code-subtitle text-center">
            Enter the 6-digit code we sent to your email.
          </p>
          <pin-input :length="6" ref="pinInput" />
          <b-btn
            class="tracker-primary-button--gradient w-100 mt-5 big-button"
            :disabled="verifyingCode"
            @click="verifyCode"
          >
            <template v-if="verifyingCode">
              <i class="fa fa-spinner fa-spin mr-2"></i>
            </template>Verify Code
          </b-btn>
          <div class="mt-5 text-center">
            <b-link class="resend-code" @click="() => requestPasswordReset(true)">Resend password reset code</b-link>
          </div>
        </b-form>

        <b-form class="enter-new-password" v-if="step === 'enter_new_password'">
          <b-form-group
            class="tracker-form-group w-100"
            label="New Password"
            :invalid-feedback="$_getError('new_password')"
            :state="$_hasError('new_password')"
          >
            <b-form-input
              class="tracker-form-input"
              data-vv-as="New Password"
              autocomplete="off"
              :state="$_hasError('new_password')"
              v-validate="'required|min:8'"
              type="password"
              v-model="newPassword"
              name="new_password"
              key="new_password"
              placeholder="New Password"
              @keyup.enter="resetPassword"
              ref="new_password"
            />
          </b-form-group>

          <b-form-group
            class="tracker-form-group w-100"
            label="Confirm New Password"
            :invalid-feedback="$_getError('confirm_new_password')"
            :state="$_hasError('confirm_new_password')"
          >
            <b-form-input
              class="tracker-form-input"
              data-vv-as="Confirm New Password"
              autocomplete="off"
              :state="$_hasError('confirm_new_password')"
              v-validate="'required|confirmed:new_password'"
              type="password"
              name="confirm_new_password"
              key="confirm_new_password"
              v-model="confirmNewPassword"
              placeholder="Confirm New Password"
              @keyup.enter="resetPassword"
            />
          </b-form-group>

          <b-btn
            class="tracker-primary-button--gradient w-100 mt-4 big-button"
            :disabled="resettingPassword"
            @click="resetPassword"
          >
            <template v-if="resettingPassword">
              <i class="fa fa-spinner fa-spin mr-2"></i>
            </template>Reset Password
          </b-btn>
        </b-form>

        <b-btn class="tracker-primary-button--link mt-5 mx-auto w-100" @click="$router.push({ name: 'Login' })"><i class="fa fa-arrow-left mr-2"></i>Back to Login</b-btn>
      </div>
    </div>
  </auth-container>
</template>

<script>
import PinInput from '@/components/Utils/PinInput/PinInput.vue';
import AuthContainer from '@/containers/AuthContainer.vue';
import notifications from '@sh/mixins/notifications';

export default {
  name: 'PasswordReset',
  components: {
    PinInput,
    AuthContainer,
  },
  mixins: [notifications],
  data() {
    return {
      loading: false,
      email: '',
      confirmationCode: '',
      step: 'confirm_email',
      verifyingCode: false,
      resettingPassword: false,
      newPassword: '',
      confirmNewPassword: '',
    };
  },
  methods: {
    async requestPasswordReset(showNotification = false) {
      if (!(await this.validate())) {
        return;
      }

      try {
        this.loading = true;
        const payload = {
          email: this.email,
        };
        await this.$api.passwordReset.request(payload);
        this.step = 'redeem_code';
        if (showNotification) {
          this.$n_successNotification({ title: 'Password reset code sent successfully!' });
        }
      } catch (err) {
        this.$n_failNotification({ title: err?.response?.data?.message || 'Could not send password reset code!' });
      } finally {
        this.loading = false;
      }
    },
    async verifyCode() {
      const code = this.$refs.pinInput.getValue();
      this.confirmationCode = code;
      try {
        this.verifyingCode = true;
        await this.$api.passwordReset.verifyCode({ code: this.confirmationCode, email: this.email });
        this.step = 'enter_new_password';
        this.$n_successNotification({ title: 'Code verified successfully!' });
      } catch (err) {
        this.$n_failNotification({ title: err?.response?.data?.message || 'Failed to verify code' });
      } finally {
        this.verifyingCode = false;
      }
    },
    async resetPassword() {
      if (!(await this.validate())) {
        return;
      }

      try {
        this.resettingPassword = true;

        const payload = {
          email: this.email,
          code: this.confirmationCode,
          newPassword: this.newPassword,
        };

        await this.$api.passwordReset.resetPassword(payload);
        this.$n_successNotification({ title: 'Password reset successfully!' });
        this.$router.push({ name: 'Login' });
      } catch (err) {
        this.$n_failNotification({ title: err?.response?.data?.message || 'Failed to reset password' });
      } finally {
        this.resettingPassword = false;
      }
    },
    $_goToLogin() {
      this.$router.push({ name: 'Login' });
    },
    async validate() {
      try {
        return await this.$validator.validateAll();
      } catch (error) {
        return false;
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_removeError(name) {
      this.$validator.errors.remove(name);
    },
  },
};
</script>

<style lang="scss">
.password-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .enter-new-password {
    margin-top: -2rem;
  }

  .resend-code {
    font-size: 1.4rem;
    font-weight: 300;
  }

  .logo {
    margin-bottom: 2.5rem;
    text-align: center;
    width: 100%;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .subtitle {
    font-size: 1.4rem;
    font-weight: 300;
    color: $text-secondary;
  }

  .big-button {
    font-size: 1.6rem;
    font-weight: 500;
    padding: 1rem;
  }

  .pin-code-subtitle {
    margin-bottom: 2.1rem;
  }

  .container-wrapper {
    background-color: white;
    border-radius: 0.8rem;

    .col-form-label {
      background: white;
    }
    .tracker-form-input {
      background: white;
      border-color: #e4e6e8;
      color: #252d41;
    }
  }
}
</style>
